import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Home.css";
import computer from "./images/computer2.png";
import Aos from "aos";
import "aos/dist/aos.css";
import seo from "./images/seo1.png";
import sup from "./images/sup1.png";
import webdeg from "./images/webdeg1.png";
import webdev from "./images/webdev1.png";
import { useNavigate } from "react-router-dom";
import idea from "./images/idea1.png";
import dynamic from "./images/dynamic1.png";
import speedy from "./images/speedy1.png";
import trusted from "./images/trusted1.png";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from "swiper";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";


// Import Swiper styles
import 'swiper/css';
import 'swiper/css/autoplay'

function Home() {
  const navigate = useNavigate();
  const whatsapp = <FontAwesomeIcon icon={faWhatsapp} />;

  useEffect(() => {
    Aos.init({ duration: 1500 });
  }, []);
  return (
    <div className="container-fluid p-0 m-0">
      {/* WHATS UP BRO */}
      {/* <img src={whatsapp} alt=""/> */}
        <a className="wp-icon" href="https://wa.me/905317865999" target={"_blank"}>
          {whatsapp}
        </a>

      {/* GİRİŞ YAZISI */}
      <div className="row" >
        <div className="col-md-12 nav-text-kapsayici text-center">
          <p className="nav-text">&#128075;Merhaba Dünya...&#128522; </p>
          
        </div>
      </div>

      {/* WRAPPER */}
      <div className="row ">
        <div className="col-12 text-center p-0">
          <div className="wrapper">
            <div className="row mt-4">
              <div className="col-md-12">
                <img className="img-fluid" src={computer} alt="" />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-12">
                <h1 className="baslik">
                  <span className="multiText"></span>
                  <br /> Web Tasarım
                </h1>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12" data-aos="zoom-in-up">
                <button onClick={() => navigate("/iletisim")} type="button" className="btn btn-lg button py-3">
                  Teklif Alın
                </button>
                <button onClick={() => navigate("/referanslarimiz")} type="button" className="btn btn-lg button py-3">
                  Referanslarımız
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*slider */}
      <div className="row mt-5 ">
        <div className="col-md-6 offset-md-3 text-center">
          <h3>
            Süreç Nasıl İşliyor?
          </h3>
        </div>
      </div>
      <div className="row mt-2 mb-5 ">
        <div className="col-md-6  offset-md-3 p-4">
          <Swiper
            spaceBetween={50}
            slidesPerView={1}
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
            speed={5000}
            loop={true}
            pagination={{
              clickable: true,
            }}
            navigation={true}
            modules={[Autoplay, Pagination, Navigation]}
          >
            <SwiperSlide data-aos="fade-down">
              <div className="text-center" >
                <h4>Sizi Dinliyoruz</h4>
              </div>
              <p>
                İhtiyaç ve isteklerinizi dinleyerek projeye yönelik
                adımları bilgilerimiz ve öngörülerimizle karşılıklı fikir alışverişi yaparak süreci başlatıyoruz.
              </p>
            </SwiperSlide>
            <SwiperSlide >
              <div className="text-center">
                <h4>Araştırıyoruz</h4>
              </div>
              <p>
                Sizden aldığımız ihtiyaç ve istek talepleri doğrultusunda araştırmalar yapıp,
                edindiğimiz bilgiler sonucunda sizinle tekrar iletişime geçiyoruz.
              </p>

            </SwiperSlide>
            <SwiperSlide >
              <div className="text-center">
                <h4>Sunuyoruz</h4>
              </div>
              <p>
                Şu ana kadar elde ettiğimiz veriler ve hemfikir olduğumuz konularla tasarımcılarımız
                ile birlikte çalışarak projenin demosunu hazırlayıp size sunuyoruz.
              </p>

            </SwiperSlide>
            <SwiperSlide >
              <div className="text-center">
                <h4>Geliştiriyoruz</h4>
              </div>
              <p>
                Bütün bu adımların ardından ortak fikirlerden oluşturduğumuz
                projenizi geliştiriyoruz ve testlerin ardından söz verdiğimiz zaman içerisinde tamamlıyor ve teslim ediyoruz.
              </p>

            </SwiperSlide>
            <SwiperSlide >
              <div className="text-center">
                <h4>Teslimat Sonrası Destek</h4>
              </div>
              <p>
                Müşterilerimizin herhangi bir sorun veya talebi doğrultusunda
                teslimat sonrası desteğimiz ile her koşulda müşterimizin yanında bulunuyoruz.
              </p>

            </SwiperSlide>
            
          </Swiper>
        </div>
      </div>

      {/* Hizmetler */}
      <div className="row border-bottom border-top">
        <div className="row my-3 ">
          <div className="col-md-12 text-center">
            <h2 className="font-weight-bold mb-2">Neler yapıyoruz?</h2>
          </div>
        </div>

        <div className="row pb-5 mb-4 mx-auto" data-aos="fade-up">
          <div
            className="col-lg-3 col-md-6 mb-4 mb-lg-0"
            onClick={() => navigate("/hizmetlerimiz")}
          >
            <div className="card rounded shadow-sm border-0">
              <div className="card-body p-4">
                <div className="cardImg">
                  <img
                    src={webdeg}
                    alt=""
                    className="img-fluid d-block mx-auto mb-3"
                  />
                </div>
                <h5>
                  <a href="#" className="card-a">
                    Web Tasarım
                  </a>
                </h5>
                <p className="small text-muted font-italic">
                  Web tasarım, bir web sitesinin kullanıcı arayüzü tasarımı ve
                  geliştirilmesi sürecidir. Bu süreçte, tasarımcılar web
                  sitesinin görünümünü, kullanıcı deneyimini ve
                  erişilebilirliğini iyileştirmek için tasarım ve teknik
                  becerilerini kullanırlar.
                </p>
                <ul className="list-inline small">
                  <li className="list-inline-item m-0">
                    <i className="fa fa-star text-success"></i>
                  </li>
                  <li className="list-inline-item m-0">
                    <i className="fa fa-star text-success"></i>
                  </li>
                  <li className="list-inline-item m-0">
                    <i className="fa fa-star text-success"></i>
                  </li>
                  <li className="list-inline-item m-0">
                    <i className="fa fa-star text-success"></i>
                  </li>
                  <li className="list-inline-item m-0">
                    <i className="fa fa-star-o text-success"></i>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 mb-4 mb-lg-0">
            <div
              className="card rounded shadow-sm border-0"
              onClick={() => navigate("hizmetlerimiz")}
            >
              <div className="card-body p-4">
                <div className="cardImg">
                  <img
                    src={webdev}
                    alt=""
                    className="img-fluid d-block mx-auto mb-3"
                  />
                </div>
                <h5>
                  {" "}
                  <a href="#" className="card-a">
                    Web Yazılım
                  </a>
                </h5>
                <p className="small text-muted font-italic">
                  Web yazılımı, internet üzerinde çalışan yazılımları ifade
                  eder. Web yazılımı, web tarayıcısı üzerinden çalışan herhangi
                  bir yazılımı kapsayabilir. Örneğin, bir e-ticaret sitesi, bir
                  sosyal medya platformu veya bir online oyun, web yazılımı
                  olarak kabul edilebilir.
                </p>
                <ul className="list-inline small">
                  <li className="list-inline-item m-0">
                    <i className="fa fa-star text-success"></i>
                  </li>
                  <li className="list-inline-item m-0">
                    <i className="fa fa-star text-success"></i>
                  </li>
                  <li className="list-inline-item m-0">
                    <i className="fa fa-star text-success"></i>
                  </li>
                  <li className="list-inline-item m-0">
                    <i className="fa fa-star-o text-success"></i>
                  </li>
                  <li className="list-inline-item m-0">
                    <i className="fa fa-star-o text-success"></i>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 mb-4 mb-lg-0">
            <div
              className="card rounded shadow-sm border-0"
              onClick={() => navigate("hizmetlerimiz")}
            >
              <div className="card-body p-4">
                <div className="cardImg">
                  <img
                    src={seo}
                    alt=""
                    className="img-fluid d-block mx-auto mb-3"
                  />
                </div>
                <h5>
                  {" "}
                  <a href="#" className="card-a">
                    SEO
                  </a>
                </h5>
                <p className="small text-muted font-italic">
                  SEO, “Arama Motoru Optimizasyonu” anlamına gelir. Web
                  sitelerinin arama motorlarında daha üst sıralarda çıkması için
                  yapılan çalışmaların tümüdür. SEO çalışmaları, web
                  sayfalarının arama motorları tarafından daha kolay taranmasını
                  ve anlaşılmasını sağlar.
                </p>
                <ul className="list-inline small">
                  <li className="list-inline-item m-0">
                    <i className="fa fa-star text-success"></i>
                  </li>
                  <li className="list-inline-item m-0">
                    <i className="fa fa-star text-success"></i>
                  </li>
                  <li className="list-inline-item m-0">
                    <i className="fa fa-star text-success"></i>
                  </li>
                  <li className="list-inline-item m-0">
                    <i className="fa fa-star text-success"></i>
                  </li>
                  <li className="list-inline-item m-0">
                    <i className="fa fa-star text-success"></i>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 mb-4 mb-lg-0">
            <div
              className="card rounded shadow-sm border-0"
              onClick={() => navigate("hizmetlerimiz")}
            >
              <div className="card-body p-4">
                <div className="cardImg">
                  <img
                    src={sup}
                    alt=""
                    className="img-fluid d-block mx-auto mb-3"
                  />
                </div>
                <h5>
                  <a href="#" className="card-a">
                    Teslimat Sonrası Destek
                  </a>
                </h5>
                <p className="small text-muted font-italic">
                  Teslimat sonrasında herhangi bir destek gereksinimi duymanız
                  <br/> 
                  halinde bizimle e posta, telefon 
                  
                  veya herhangi bir iletişim
                  aracıyla iletişime geçebilirsiniz.
                  
                  
                  Müşterilerimizin teslimat öncesi veya sonrasında her konuda
                  yanındayız.
                  
                </p>
                <ul className="list-inline small">
                  <li className="list-inline-item m-0">
                    <i className="fa fa-star text-success"></i>
                  </li>
                  <li className="list-inline-item m-0">
                    <i className="fa fa-star text-success"></i>
                  </li>
                  <li className="list-inline-item m-0">
                    <i className="fa fa-star text-success"></i>
                  </li>
                  <li className="list-inline-item m-0">
                    <i className="fa fa-star text-success"></i>
                  </li>
                  <li className="list-inline-item m-0">
                    <i className="fa fa-star-o text-success"></i>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* özellikler*/}
      <div className="row border-bottom  my-4">
        <div className="col-md-6 offset-md-3 ">
          {/* genc ve finamik */}
          <div className="row mt-5 mb-5 my-shadow p-4" data-aos="fade-up">
            <div className="col-md-8 col-sm-9 col-xs-9">
              <div className="col headerCol">
                <h3>Genç ve Dinamik Kadro</h3>
              </div>
              <p>
                Genç ve dinamik kadro ile, yeniliklere açık, farklı bakış
                açılarına sahip, enerjik, ileri görüşlü ve üretken bir ekibiz.
              </p>
            </div>
            <div className="col-md-4 col-sm-3 col-xs-3 text-center">
              <div className="img-size-div">
                <img className="img-fluid " src={dynamic} alt="" />
              </div>
            </div>
          </div>
          {/*Yenilikçi */}
          <div className="row mb-5 my-shadow p-4" data-aos="fade-up">
            <div className="col-md-4 col-sm-3">
              <div className="img-size-div">
                <img className="img-fluid " src={idea} alt="" />
              </div>
            </div>
            <div className="col-md-8 col-sm-9">
              <div className="col headerCol">
                <h3>Yenilikçi</h3>
              </div>
              <p>
                Yenilikçi ekibimiz ile, yeni fikirler geliştirerek, yeni yöntemler bularak ve bunları uygulayarak her zaman bir adım öndeyiz.
                Sürekli bir merak duygusu içinde, yeni şöyler öğrenmek ve denemek bizi biz yapan özelliklerimizden biridir.
              </p>
            </div>
          </div>
          {/* Güvenilir */}
          <div className="row mb-5 my-shadow p-4" data-aos="fade-up">
            <div className="col-md-8 col-sm-9">
              <div className="col headerCol">
                <h3>Güvenilir</h3>
              </div>
              <p>
                Müşterilerimize karşı daima güvenilir bir tavır sergileriz. Süreçleri dürüst ve şeffaf olarak ilerletmek bizim için çok önemlidir. Bu temel ilkeler karşılıklı olduğunda daha
                kaliteli bir iş ortaya çıkacağını düşünmekteyiz.
              </p>
            </div>
            <div className="col-md-4 col-sm-3 text-center">
              <div className="img-size-div">
                <img className="img-fluid " src={trusted} alt="" />
              </div>
            </div>
          </div>
          {/* Hızlı ve Müşteri Odaklı */}
          <div className="row mb-5 my-shadow p-4" data-aos="fade-up">
            <div className="col-md-4 col-sm-3 text-center">
              <div className="img-size-div">
                <img className="img-fluid " src={speedy} alt="" />
              </div>
            </div>
            <div className="col-md-8 col-sm-9" >
              <div className="col headerCol">
                <h3>Hızlı ve Müşteri Odaklı</h3>
              </div>
              <p>
                Müşterilerimizin ihtiyaç ve isteklerini en kısa sürede ve en iyi şekilde karşılamak için elimizden gelen bütün gayret ve çabayı gösteriyoruz.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
