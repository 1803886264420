import { useState } from 'react'
import { NavLink } from 'react-router-dom'
import  Brand  from './images/logo_light.png'
import './navbar.css'

const Navbar = () => {
  const [showNavbar, setShowNavbar] = useState(false)

  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar)
  }

  return (
    <nav className="navbar p-0 fixed-top">
      <div className="container-fluid">
        <div className="logo">
          <img src={Brand} alt="Brand" />
        </div>
        {/* <div className="menu-icon" onClick={handleShowNavbar}>
          <Hamburger />
        </div> */}
        <div className={`nav-elements  ${showNavbar && 'active'}`}>
          <ul>
            <li>
              <NavLink to="/" className="cool-link">Anasayfa</NavLink>
            </li>
            <li>
              <NavLink to="/hakkimizda" className="cool-link">Hakkımızda</NavLink>
            </li>
            <li>
              <NavLink to="/hizmetlerimiz" className="cool-link">Hizmetlerimiz</NavLink>
            </li>
            <li>
              <NavLink to="/referanslarimiz" className="cool-link">Referanslarımız</NavLink>
            </li>
            <li>
              <NavLink to="/iletisim" className="cool-link">İletişim</NavLink>
            </li>
          </ul>
        </div>
        <span class="navTrigger" onClick={handleShowNavbar}>
                <i></i>
                <i></i>
                <i></i>
            </span>
      </div>



    </nav>


  )
}

export default Navbar