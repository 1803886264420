import React from "react";
import Brand from "../Navbar/images/logo_light.png";
import "./footer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { NavLink } from "react-router-dom";

function Footer() {
  const instagram = <FontAwesomeIcon icon={faInstagram} />;
  const linkedin = <FontAwesomeIcon icon={faLinkedin} />;
  const twitter = <FontAwesomeIcon icon={faTwitter} />;
  const phone = <FontAwesomeIcon icon={faPhone} />;
  const mail = <FontAwesomeIcon icon={faEnvelope} />;
  const facebook = <FontAwesomeIcon icon={faFacebook} />;


  return (
    <div className="container-fluid p-0 footer">
      <footer className="footer-distributed">
        <div className="footer-left">
          <div>
            <img className="footer-logo" src={Brand} alt="" />
          </div>

          <p className="footer-links">
            <NavLink to={"/"} className="link-1">
              Anasayfa
            </NavLink>

            <NavLink to={"/hakkimizda"}>Hakkımızda</NavLink>

            <NavLink to={"/hizmetlerimiz"}>Hizmetlerimiz</NavLink>

            <NavLink to={"/referanslarimiz"}>Referanslarımız</NavLink>

            <NavLink to={"/iletisim"}>İletişim</NavLink>
          </p>

          <p className="footer-company-name">© 2023 teknobbd.com . Tüm hakları saklıdır.</p>
        </div>

        <div className="footer-center">


          <div className="footer-contact-div">
            <a className="footer-contact-a">{phone}</a>
            <p className="footer-contact-p">+90 531 786 5999</p>
          </div>

          <div className="footer-contact-div">
            <a className="footer-contact-a">{mail}</a>
            <p className="footer-contant-p">
              <NavLink to={"/iletisim"}>info@teknobbd.com</NavLink>
            </p>
          </div>
        </div>

        <div className="footer-right">
          <p className="footer-company-about">
            <span>Hakkımızda</span>
            Teknobbd 2023 yılının başlarında kurulan Manisa Merkezli Bir Web Yazılım Geliştirme firmasıdır. Firmamız henüz mezun olmamışken yazılım sektörüne meraklı
             öğrenciler tarafından kurulmuştur. 
            Azimli ve çalışkan ekibimiz siz değerleri müşterilerimizin daima yanındadır.
          </p>

          <div className="footer-icons">
            <a className="instagram" href="https://www.instagram.com/teknobbd/" target={"_blank"}>
                {instagram}
            </a>
            <a className="linkedin" href="https://www.linkedin.com/company/teknobbd" target={"_blank"}>
              {linkedin}
            </a>
            <a className="twitter" href="https://twitter.com/teknobbd" target={"_blank"}>
              {twitter}
            </a>
            <a className="facebook" href="https://www.facebook.com/people/Teknobbd/100090818745711/" target={"_blank"}>
              {facebook}
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
