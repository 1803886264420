import React, { useEffect } from "react";
import "./services.css";
import Aos from "aos";
import "aos/dist/aos.css";
import services from "../static_images/services.png";
import personel from "../static_images/personel-website.png"
import institutional from "../static_images/institutional-website.jpg"
import maitenance from "../static_images/maitenance.png"
import aftersales from "../static_images/after-sales.png"
import uiux from "../static_images/uiux.png"
import seo from "../Home/images/seo1.png"
import { useNavigate } from "react-router-dom";

function Services() {
  const navigate = useNavigate();

  useEffect(() => {
    Aos.init({ duration: 1500 });
  }, []);

  return (
    <div
      className="container-fluid mb-5 p-0"
      style={{ height: "800px !important",backgroundColor:"rgb(250,250,250)" }}
    >
      <div className="row ">
        <div className="col-md-12 text-center p-0">
          <div className="services-wrapper">
            <div className="row mt-5 p-4">
              <div className="col-md-12">
                <div className="row">
                    <div className="col-md-12">
                      <h4 className="services-header">Hizmetlerimiz</h4>
                    </div>
                </div>
                <div className="row mt-5">
                
                  <div className="col-md-12">
                    <img src={services} className="img-fluid" alt="" />
                  </div>
                </div>
                <div className="row">
                <div className="col-md-12 mt-5">
                    <div className="row">
                      <div className="col-md-12">
                      
                        <p className="services-p">
                          Ayrıcalıklı hizmetlerimizden faydalanarak
                          oluşturduğunuz web siteniz ile siz de dijital
                          dünyadaki yerinizi alabilirsiniz.
                        </p>
                      </div>
                    </div>
                    <div className="row mt-5 mb-5">
                      <div className="col-md-12">

                        <div onClick={() => navigate("/iletisim")} className="btn btn-primary p-3 mx-3 btn-lg btnservices">Teklif Alın</div>
                        <div onClick={() => navigate("/hakkimizda")} className="btn btn-primary p-3 mx-3 btn-lg btnservices">Biz Kimiz?</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-8 offset-md-2 ">
          <div className="row mt-5">
            <div className="col-md-12">
                <div className="row mt-5">
                  <div className="col-md-12">
                    <div className="card services-card" data-aos="fade-down">
                    <div className="card-header text-center bg-white">
                        <img src={personel} alt="" className="img-fluid" style={{width:"400px"}} />
                    </div>
                      <div className="card-body">
                      <h4>Kişisel Web Sitesi</h4>
                    <p>Blog, duyuru, tanıtım, foto galeri ve portfolyo gibi özelliklere sahip olan sitelerdir. Siz de ihtiyacınız olan özelliklere sahip web sitesini oluşturarak dijital ortamda çevre 
                      edinebilirsiniz. Web sitenizde, kendiniz ve yaptığınız işler hakkında yazılar yazarak kazançlar elde edebilirsiniz. Daha önce yaptığınız işlerden bir portfolyo oluşturup olası müşterileriniz 
                      üzerinde kendiniz ve tecrübeleriniz hakkında bir izlenim bırakabilirsiniz. Çok güzel fotoğraflar mı çekiyorsunuz? Bunları online bir galeri oluşturarak diğer insanların görmesi için 
                      yayınlayabilirsiniz. Kendinize özel blog oluşturup yazılar yazabilir ve okuyucularınız ile paylaşabilirsiniz. Siz de bir web sitesi ihtiyacım var diyorsanız bize ulaşın ve dilediğiniz gibi 
                      size bir web sitesi hazırlayalım.  </p>
                      </div>
                    </div>   
                  </div>


                </div>
                <div className="row mt-5">
                  <div className="col-md-12">
                    <div className="card services-card" data-aos="fade-down">
                    <div className="card-header text-center bg-white">
                        <img src={institutional} className="img-fluid" alt="" style={{width:"400px"}} />
                    </div>
                      <div className="card-body">
                      <h4>Kurumsal Web Sitesi</h4>
                    <p>İşinizi büyütmek mi istiyorsunuz? Daha fazla müşteriye ulaşmak mı istiyorsunuz? Kurumsal bir web sitesi ile bunlar artık çok kolay. Küçük ve orta ölçekli firmaların ihtiyacı olan
                       web sitelerini hazırlıyoruz ve işlerini çevrimiçi yönetebiliyorlar. Web sitenizde oluşturacağımız bir panel ile ürünleri, stokları ve takip etmek istediğiniz her şeyi kayıt altına
                        alabilirsiniz. Şirketiniz hakkında bilgiler verebilir , ürünleriniz tanıtabilirsiniz . Dijital dönüşüme ayak uydurarak rakiplerinizin birkaç adım önüne geçebilirsiniz. Siz de 
                        kurumsal web sitesi ile sektörünüzde daha çok tanınan bir firma olabilirsiniz. İnternetin gücünü kullanarak firmanızı daha yukarılara taşıyabilirsiniz.  </p>
                      </div>
                    </div>   
                  </div>


                </div>


            <div className="row mt-5">
            <div className="col-md-12">
                  <div className="card services-card" data-aos="fade-down">
                  <div className="card-header text-center bg-white">
                        <img src={maitenance} alt="" className="img-fluid" style={{width:"300px"}} />
                      </div>
                      <div className="card-body">
                      <h4>Web Sitesi Bakımı</h4>
                    <p>
                    Kusursuz bir çalışma için web sitesi bakımı, güncellemeler, düzenlemeler ve yeni içerikler ekleyerek sitenizi her zaman optimize çalışmasını sağlıyoruz. Belirli periyotlarla siz de
                     web sitesi bakımı yaptırarak sitenizi güncel tutabilirsiniz. Düzenli kontroller ile web sitenizde oluşabilecek sorunları hataları önleyebilir ve güvenli bir şekilde kullanıcılara sunabilirsiniz.
                    </p>
                      </div>
                    </div>
            </div>

            </div>
            <div className="row mt-5">
            <div className="col-md-12">
                    <div className="card services-card" data-aos="fade-down">
                      <div className="card-header text-center bg-white">
                        <img src={aftersales} alt="" className="img-fluid" style={{width:"300px"}} />
                      </div>
                      <div className="card-body">
                      <h4>Satış Sonrası Destek</h4>
                    <p>
                    Ürün tesliminden sonra müşterilerimizle daima iletişim halinde kalırız. Müşteri memnuniyeti bizim için çok önemlidir. Herhangi bir sorun veya talep doğrultusunda müşterilerimiz
                     iletişim araçlarımızdan birisi ile bizimle temas kurabilir. Müşterilerimizin hizmetlerimiz hakkındaki fikirlerini ve geri dönüşlerini çok önemsiyoruz. Zaman zaman biz de müşterilerimiz
                      ile iletişime geçip müşterilerimizin daima yanlarında olduğumuzu hissetmelerini sağlıyoruz. Satış sonrası destek hizmeti ile sorunlarınız, talepleriniz var ise şikayetlerinizi en kısa 
                      sürede çözüme ulaştırıyoruz
                    </p>
                      </div>
                    </div>
                  
                  </div>
            </div>


                <div className="row mt-5">

                  <div className="col-md-12">
                  <div className="card services-card" data-aos="fade-down">
                  <div className="card-header text-center bg-white">
                        <img src={uiux} alt="" className="img-fluid" style={{width:"300px"}} />
                      </div>
                      <div className="card-body">
                      <h4>UI / UX WEB TASARIM</h4>
                      <p>
                      UX – User Experience, kullanıcıların bir tasarımdaki tüm etkileşimini kapsar. Yani, kullanıcının etkileşim süresinde yaşadığı iyi veya kötü bütün deneyimleriyle ilgilidir.UX, 
                      kullanıcılarla ilgili birtakım testlerden aldığı verilerle şekillenebilir. Tasarımda yer alacak detayların, nerede ne zaman ve nasıl yer alacağına ilişkin konularla, kullanıcı etkileşimini
                       kurgular.

                      UI- User Interface, tasarımdaki ara yüzlerle yani, dış tasarımla ilgilenir. UX ile UI arasındaki fark UX kullanıcı deneyiminden yola çıkarak tasarımla ile kullanıcın etkileşimiyle ilgilenirken,
                      UI kullanıcı etkileşiminden çok tasarımın kendisi ile ilgilenir.
                      UX, kullanıcıların etkileşim sürecini düzenler ve iyileştirirken, UI görsel arayüzü kullanıcı dostu ve göze hitap edecek şekilde düzenler. 

                      </p>
                      </div>
                    </div>
                  </div>

                </div>


              <div className="row mt-5">
              <div className="col-md-12">
                  <div className="card services-card" data-aos="fade-down">
                  <div className="card-header text-center bg-white">
                        <img src={seo} alt="" className="img-fluid" style={{width:"300px"}} />
                      </div>
                      <div className="card-body">
                      <h4>SEO (Arama Motoru Optimizasyonu)</h4>
                    <p> 
                    SEO yani arama motoru optimizasyonu web sitenizin Google, Yandex, Opera gibi arama motorlarının web sitenizi daha rahat bir şekilde tarayıp kullanıcıya daha hızlı ve ön sıralarda listelemesi
                     için yapılan çalışmalar bütünüdür. 

                    Siz de SEO (Arama Motoru Optimizasyonu) hizmetimiz ile web sitenizi arama motorlarında öncelikli hala getirip rakip sitelerin daha üst sıralarında listelenip potansiyel müşteri ve 
                    kullanıcılarınıza çok daha rahat ve kolay bir şekilde ulaşabilirsiniz. 

                    </p>
                      </div>
                    </div>
                  </div>
              </div>

              </div>
          </div>


        </div>
      </div>
    </div>
  );
}

export default Services;
