import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import emailjs from 'emailjs-com';
import "./contact.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useFormik } from 'formik';
import { mailSchema } from "../../schemas";
import ErrorMessage from '../../Components/Error/ErrorMessage'
import { useNavigate } from "react-router-dom";

function Contact() {
  const instagram = <FontAwesomeIcon icon={faInstagram} />;
  const notify = () => toast("Mailiniz gönderildi!");
  const linkedin = <FontAwesomeIcon icon={faLinkedin} />;
  const twitter = <FontAwesomeIcon icon={faTwitter} />;
  const phone = <FontAwesomeIcon icon={faPhone} />;
  const mail = <FontAwesomeIcon icon={faEnvelope} />;
  const facebook = <FontAwesomeIcon icon={faFacebook} />;
  const location = <FontAwesomeIcon icon={faLocationDot} />;


  const [buttonState, setButtonState] = useState('Gönder');
  const formik = useFormik({
    //we have created our initailValues object in a format EmailJS accepts
    initialValues: {
        name: '',
        surname: '',
        email: '',
        phone: '',
        message: ''
      },
    validationSchema:mailSchema,
    onSubmit: (values,{ resetForm }) => {
       try{
      emailjs.send("service_jr9sbkl" , "template_knokfvq", values, "IvbEmSKnn_qq6YRlf")
        .then(() => {
            notify();    
           resetForm();
              });
       }
       catch {
        
          setButtonState('Send Email');
         
      }
       },
});



  return (
    <div className="container-fluid contact-main-area">
      <div className="row">
        <div className="col-lg-8 offset-lg-2 col-md-12">
          <div className="row my-5 contact-main">
            <div className="col-md-7 left-area">
              <form className="formcontact" onSubmit={formik.handleSubmit}  >
                <div className="row mt-4">
                  <div className="col-md-6 text-center">
                    <label className="contact-label" htmlFor="name">İsim</label>
                    <input name="name" onChange={formik.handleChange} value={formik.values.name} onBlur={formik.handleBlur} id="name" type="text" className="form-control" />
                    {formik.errors.name && formik.touched.name && <ErrorMessage text={formik.errors.name}/> }
                  </div>
                  <div className="col-md-6 text-center">
                    <label className="contact-label" htmlFor="lastName">Soyisim</label>
                    <input name="surname" id="lastName" type="text" className="form-control" onChange ={formik.handleChange} 
                    value = {formik.values.surname} onBlur={formik.handleBlur}/>
                      {formik.errors.name && formik.touched.name && <ErrorMessage text={formik.errors.name}/> }
                  </div>
                </div>
                <div className="row mt-5">
                  <div className="col-md-6 text-center">
                    <label className="contact-label" htmlFor="email">Email</label>
                    <input name="email" id="email" type="email" className="form-control"  onChange ={formik.handleChange} 
                    value = {formik.values.email} onBlur={formik.handleBlur}/>
                      {formik.errors.name && formik.touched.name && <ErrorMessage text={formik.errors.name}/> }
                  </div>
                  <div className="col-md-6 text-center">
                    <label className="contact-label" htmlFor="adress">Telefon Numarası</label>
                    <input name="phone" id="adress" type="text" className="form-control"  onChange ={formik.handleChange} 
                    value = {formik.values.phone} onBlur={formik.handleBlur} />
                     {formik.errors.phone && formik.touched.phone && <ErrorMessage text={formik.errors.phone}/> }
                  </div>
                </div>
                <div className="row mt-5">
                  <div className="col-md-12 text-center">
                    <label className="contact-label" htmlFor="message" class="form-label">
                      Mesajınız
                    </label>
                    <textarea
                        name="message"
                        class="form-control"
                        id="message"
                        rows="8"
                        onChange ={formik.handleChange} 
                        value = {formik.values.message}
                        onBlur={formik.handleBlur} 
                    ></textarea>
                    {formik.errors.message &&formik.touched.message && <ErrorMessage text={formik.errors.message}/> }
                  </div>
                </div>
                <div className="row mt-5 mb-5">
                  <div className="col-md-12 text-center ">
                  <button disabled={formik.isSubmitting} type="submit" className="btn main-btn btn-primary ">
                    <span>{buttonState}</span>
                </button>
                  </div>
                </div>
              </form>
            </div>

            <div className="col-md-5 right-area py-3">
              <div className="row">
                <div className="col-md-12 text-center contact-header">
                  <h3>Bizimle İletişime Geçin</h3>
                </div>
              </div>
              <div className="row mt-5">
                <div className="col-md-8 offset-md-2">
                  Teklif almak, soru sormak, istek, şikayet ve önerileriniz için
                  yandaki formu kullanarak bizimle iletişime geçebilirsiniz.
                </div>
              </div>
              <div className="row mt-5">
                <div className="col-md-8 offset-md-2">
                  {phone} <span>+90 531 786 59 99</span>
                </div>
              </div>
              <div className="row mt-5">
                <div className="col-md-8 offset-md-2">
                  {mail} <span>info@teknobbd.com</span>
                </div>
              </div>
              <div className="row mt-5">
                <div className="col-md-8 offset-md-2">
                  {location} <span>Manisa</span>
                </div>
              </div>

              <div className="row mt-5">
                <div className="col-lg-8 offset-lg-2 col-md-12">
                  <div className="contact-social-media text-center">
                    <a className="instagram" href="https://www.instagram.com/teknobbd/" target={"_blank"}>
                      {instagram}
                    </a>
                    <a className="linkedin" href="https://www.linkedin.com/company/teknobbd" target={"_blank"}>
                      {linkedin}
                    </a>
                    <a className="twitter" href="https://twitter.com/teknobbd" target={"_blank"}>
                      {twitter}
                    </a>
                    <a
                      className="facebook"
                      href="https://www.facebook.com/people/Teknobbd/100090818745711/"
                      target={"_blank"}
                    >
                      {facebook}
                    </a>
                  </div>

                  {/* <div className="row text-center contact-social-media">
                                        <div className="col-md-3"><a href="#">{linkedin}</a></div>
                                        <div className="col-md-3"><a href="#">{twitter}</a></div>
                                        <div className="col-md-3"><a href="#">{instagram}</a></div>
                                        <div className="col-md-3"><a href="#">{facebook}</a></div>
                                    </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer  />
    </div>
    
  );
}

export default Contact;
