import React from 'react'
import waiting from '../static_images/waiting.gif'

function References() {
  return (
    <div className="container text-center d-flex jusfity-content-center mb-5 mt-5">
      <div className="row text-center m-auto mt-5 mb-5">
        <div className="col-md-12">
              <div className="row mt-5 mb-5">
                <div className="col-md-12 text-center d-flex justify-content-center">
                  <h2>Çok Yakında Burada </h2>
                </div>
              </div>
              <div className="row ">
                <div className="col-md-12 d-flex justify-content-center text-center">
                  <img src={waiting} className="img-fluid" alt="" style={{borderRadius:"10px"}}/>
                </div>
              </div>
        </div>
      </div>
    </div>
  
  )
}

export default References