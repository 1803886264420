import { Helmet } from "react-helmet";
import { Route, Routes } from "react-router-dom";
import Footer from "./Components/Footer/Footer";
import Navbar from "./Components/Navbar/Navbar";
import About from "./pages/About/About";
import Contact from "./pages/Contact/Contact";
import Email from "./pages/Email/Email";
import Home from "./pages/Home/Home";
import References from "./pages/References/References";
import Services from "./pages/Service/Services";

function App() {
  return (
    <div className="App container-fluid p-0">

      <div className="row">
        <div className="col-md-12"> 
            <Navbar/>
        </div>
      </div>
      <div className="row mx-0" style={{marginTop:"100px"}}>
          <div className="col-md-12 p-0">
          <Routes>
              <Route path="/" element={<Home/>} />
              <Route path="/hakkimizda" element={<About/>} />
              <Route path="/hizmetlerimiz" element={<Services/>} />
              <Route path="/referanslarimiz" element={<References/>} />
              <Route path="/iletisim" element={<Contact/>} />
         </Routes>
          </div>
      </div>
      <div className="row">
          <div className="col-md-12 p-0 ">
          <Footer/>
          </div>
      </div>
       {/* <Navbar/>
       <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/hakkimizda" element={<About/>} />
        <Route path="/hizmetlerimiz" element={<Services/>} />
        <Route path="/referanslarimiz" element={<References/>} />
        <Route path="/iletişim" element={<Contact/>} />
       </Routes>
       */}
    </div>
    
  );
}

export default App;
