import React, { useEffect } from "react";
import aboutus from '../static_images/aboutus.png'
import vision from '../static_images/vision.png'
import strategy from '../static_images/strategy.png'
import Aos from "aos";
import "aos/dist/aos.css";
import './about.css'

function About() {
  useEffect(() => {
    Aos.init({ duration: 1500 });
  }, []);
  return (
    <div className="container-fluid border-top border-bottom  mb-5" style={{backgroundColor:"rgb(250,250,250)"}}>
      <div className="row mt-5 mb-5 shs">
        <div className="col-md-10 offset-md-1 col-sm-12 text-start">
        <div className="row mt-5 mb-5 my-shadow p-4" style={{backgroundColor:"white"}}>
            <div class="col-md-8 col-sm-9 col-xs-9">
              <div class="col headerCol">
                <h3>Hakkımızda</h3>
              </div>
              <p>
              Teknobbd 2023 yılının başlarında kurulan Manisa Merkezli Bir Web Yazılım Geliştirme firmasıdır. Firmamız
              henüz mezun olmamışken yazılım sektörüne meraklı öğrenciler tarafından kurulmuştur. Azimli ve çalışkan ekibimiz siz değerleri müşterilerimizin daima yanındadır.
              </p>
            </div>
            <div class="col-md-4 col-sm-3 col-xs-3 text-center">
              <div className="img-size-div">
                <img class="img-fluid " src={aboutus} alt="" />
              </div>
            </div>
        </div>


        <div className="row mt-5 mb-5 my-shadow p-4" data-aos="fade-up" style={{backgroundColor:"white"}}>
            <div class="col-md-8 col-sm-9 col-xs-9">
              <div class="col headerCol">
                <h3>Vizyon</h3>
              </div>
              <p>
                Türkiyede , Web yazılım sektöründe büyük bir pazar payına sahip olmayı hedeflerken, hizmetlerimiz ve iletişimimiz ile gönlünüzde yer etmek istiyoruz.
              </p>

              <div class="col headerCol">
                <h3>Misyon</h3>
              </div>
              <p>
                Güncel teknolojiler hakkında profesyonelleşmeyi başararak ve etkili iletişim becerilerimizi kullanarak ortaya hem sizi hem kendimizi memnun edecek projeler koyacağımızı düşünüyoruz.
              </p>
            </div>

            
            <div class="col-md-4 col-sm-3 col-xs-3 text-center">
              <div className="img-size-div">
                <img class="img-fluid " src={vision} alt="" />
              </div>
            </div>
        </div>



        <div className="row mt-5 mb-5 my-shadow p-4" data-aos="fade-up" style={{backgroundColor:"white"}}>
            <div class="col-md-8 col-sm-9 col-xs-9">
              <div class="col headerCol">
                <h3>Strateji</h3>
              </div>
              <p>
              Gerek bireysel gerekse kurumsal firmanıza yapacağımız projeler ile  sizleri ve kendimizi sektörlerimizde bir adım öteye taşıyacağımız konusunda hemfikir olmanızı arzuluyoruz.  Diğer firmanları 
              inceleyerek eksik yanlarımızı kapatmak için çalışmalar yaparak hedeflerimiz doğrultusunda her gün kendimize yeni bilgiler katıyoruz. Sizlerle işbirliği yaptıkça ekibimize yeni çalışma arkadaşları katarak
              hem istihdama katkı yapmak hem de daha geniş kitlerele ulaşmayı amaçlıyoruz.
              </p>
            </div>
            <div class="col-md-4 col-sm-3 col-xs-3 text-center">
              <div className="img-size-div">
                <img class="img-fluid " src={strategy} alt="" />
              </div>
            </div>
        </div>
        
        </div>
      </div>
    </div>
  )
}

export default About